<template>
  <h1>Kompetenzen</h1>
  <div class="Image">
    <img src="../../assets/img/Beratungsschwerpunkte_01_neu.jpg" />
  </div>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.Image {
  text-align: center;
}
</style>
